import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import { Container } from "react-bootstrap";
import bg from "../../images/bg-images/about_us_our_story_bg.svg";

const section = {
  backgroundImage: `url(${bg})`,
  backgroundSize: "100% 100%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
};

const OurStory = ({ ourStory }) => {
  // const data = useStaticQuery(graphql`
  //   query imageChange {
  //     content: wpPage(slug: { eq: "acf-common-new" }) {
  //       commonFields {
  //         ourStory {
  //           mediaItemUrl
  //         }
  //       }
  //     }
  //   }
  // `)

  // const { ourStory } = data?.content?.commonFields;
  const [isMobile, setIsMobile] = React.useState(false);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  React.useEffect(() => {
    setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section style={section}>
      <Container>
        <h2 className="mb-3 text-center main-heading-36 pb-5">
          Our <span className="text-primary">Story</span>
        </h2>
        <div className="justify-content-center d-flex">
          <img
            src={ourStory?.mediaItemUrl}
            alt="our story"
            width={isMobile ? "100%" : "80%"}
          />
        </div>
      </Container>
    </section>
  );
};

export default OurStory;
