import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";

import * as styles from "./Banner.module.scss";

const Banner = () => (
  <section className={styles.careers}>
    <Container>
      <Row className="justify-content-center">
        <Col md={6}>
          <h1 className="main-banner-heading-55">
            <span className="text-primary">Tv2u</span> - Your Go-to Technology
            Partner in Innovation
          </h1>
          <p className="font-weight-normal pt-2">
            The only thing holding your business back is not being able to find
            the right software development partner. Let us take your problem off
            of your hands so you can focus on what really matters - running an
            efficient company! We help companies build innovative software with
            a team of world class engineers and an innovative approach to each
            project, so you can be confident in your success with us.
          </p>
        </Col>
        <Col md={6} className="mt-5 mt-md-0 pl-md-5 text-center">
          <StaticImage
            src="../../images/banner/about_us_hero.svg"
            alt="contact us"
            placeholder="blurred"
            loading="lazy"
          />
        </Col>
      </Row>
    </Container>
  </section>
);

export default Banner;
