import React from "react";
import { Link } from "gatsby";
import { Col, Container, Row, Button } from "react-bootstrap";

import * as styles from "./Curious.module.scss";

const TextBtnStrip = ({ text, link, btnTxt }) => {
  return (
    <section className={styles.section}>
      <Container>
        <Row className="align-items-center justify-content-between">
          <Col xl={8}>
            <h2 className="text-light mb-0">{text}</h2>
          </Col>
          <Col xl={4} className="text-center text-md-right">
            {/* <Link to={link}> */}
            <Button
              variant="light mt-3 mt-xl-0"
              className="btn_white_border"
              disabled
            >
              {btnTxt}
            </Button>
            {/* </Link> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TextBtnStrip;
