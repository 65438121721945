import React from "react";

import { Container } from "react-bootstrap";
import * as styles from "./whyIZ.module.scss";

const WhyInvoZone = ({ redefine = [], data }) => {
  const [isMobile, setIsMobile] = React.useState(false);
  const { image, mobileImage } = redefine?.length > 0 && redefine[0];

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  React.useEffect(() => {
    setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className={styles.section}>
      <Container>
        <h2 className="mb-3 text-center main-heading-36">
          Why <span className="text-primary">Tv2u?</span>
        </h2>
        <p className="text-center pb-5">
          We recruit, train, manage the resources well-versed in the latest tech
          stacks.
        </p>
        <div className={styles.WhyInvoZone}>
          {/* <img src={isMobile ? mobileImage?.mediaItemUrl : image?.mediaItemUrl} alt='redefining' width={'80%'}/> */}

          {data.map((ele, index) => (
            <div key={index} className={styles.parentInvobox}>
              <div className={styles.invobox}>
                <div
                  className={styles.invoitem}
                  style={{ border: `5px solid ${ele.color}` }}
                >
                  <img
                    src={ele.image.mediaItemUrl}
                    alt={ele.title}
                    loading="lazy"
                  />
                  <h3 style={{ color: `${ele.color}` }}>
                    {ele.prefix === "true" ? `${ele.numbers}+` : ele.numbers}
                  </h3>
                </div>
              </div>
              <h3 className={styles.text}>{ele.title}</h3>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default WhyInvoZone;
