import React from "react";
import { graphql } from "gatsby";

import MainLayout from "../layouts/MainLayout";
import { SEO, ContactSales } from "../components/common";
import {
  WhyIz,
  Team,
  Banner,
  Curious,
  OurStory,
} from "../components/about-sections";
import teamPic_Asim from "./../images/team/asim_zeeshan.png";
import teamPic_Waqas from "./../images/team/waqas_rana.jpg";
import teamPic_Bilal from "./../images/team/ahmad bilal.jpg";
import teamPic_Zeeshan from "./../images/team/Ali. zeeshan.jpg";
import teamPic_Hareem from "./../images/team/Mam Hareem.png";
import teamPic_Nael from "./../images/team/Nael.jpg";
import teamPic_Umair from "./../images/team/Umair.jpg";
import teamPic_Furqan from "./../images/team/Sir Furqan.jpg";
import teamPic_Naveed from "./../images/team/Naveed.jpg";
import teamPic_Saad from "./../images/team/saad.svg";
import storyImage from "../images/our-story.svg";

const AboutUsPage = ({ data }) => {
  // console.log(data)
  // const { seo } = data?.wpPage;
  // const { redefiningBusiness, ourStory, team, whyInvozone } =
  //   data?.content?.commonFields;
  const seo = {
    title: "About Us - Tv2u",
    metaDesc:
      "Global Software Development Outsourcing Company. Hire Tv2u offshore developers team and tech experts for web and product development.",
  };
  const team = [
    {
      name: "Furqan Aziz",
      title: "Chief Executive Officer",
      whatsapp: "tel:+601123234533",
      linkedin: "https://www.linkedin.com/in/furqan-aziz/",
      email: "furqan@invozone.com",
      image: {
        mediaItemUrl: teamPic_Furqan,
      },
    },
    {
      name: "Hareem Mohsin",
      title: "Chief Operating Officer",
      whatsapp: "tel:+923348234882",
      linkedin: "https://www.linkedin.com/in/hareem-mohsin-878500194/",
      email: "hareem@invozone.com",
      image: {
        mediaItemUrl: teamPic_Hareem,
      },
    },
    {
      name: "Waqqas Rana",
      title: "Head of Sales",
      whatsapp: "tel:+923120586610",
      linkedin: "https://www.linkedin.com/in/waqqas-ahmed-50886920/",
      email: "waqqas@invozone.com",
      image: {
        mediaItemUrl: teamPic_Waqas,
      },
    },
    {
      name: "Muhammad Saad",
      title: "Head of HR",
      whatsapp: "tel:+923107779771",
      linkedin: "https://au.linkedin.com/in/m-saad-58b83960",
      email: "saad@invozone.com",
      image: {
        mediaItemUrl: teamPic_Saad,
      },
    },
    {
      name: "Nael Shahbaz",
      title: "Head of Marketing",
      whatsapp: "tel:+923120586775",
      linkedin: "https://www.linkedin.com/in/naelshahbaz/",
      email: "nael@invozone.com",
      image: {
        mediaItemUrl: teamPic_Nael,
      },
    },
    {
      name: "Naveed Ul Aziz",
      title: "Head of Digital Transformation",
      whatsapp: "tel:+923107779772",
      linkedin: "https://www.linkedin.com/in/naveed-ul-aziz/",
      email: "naveed@invozone.com",
      image: {
        mediaItemUrl: teamPic_Naveed,
      },
    },
    {
      name: "Asim Zeeshan",
      title: "Head of Engineering",
      whatsapp: "tel:+923120586587",
      linkedin: "https://www.linkedin.com/in/asimzeeshan/",
      email: "asim@invozone.com",
      image: {
        mediaItemUrl: teamPic_Zeeshan,
      },
    },
    {
      name: "Ahmad Bilal",
      title: "Client Success Manager",
      whatsapp: "tel:+923004773566",
      linkedin: "https://www.linkedin.com/in/ahmad-bilal-03429657/",
      email: "wahid@invozone.com",
      image: {
        mediaItemUrl: teamPic_Bilal,
      },
    },
    {
      name: "Umair Qureshi ",
      title: "Head of Compliance and BO",
      whatsapp: "tel:+923214905759",
      linkedin: "https://www.linkedin.com/in/umair-qureshi-a071712a/",
      email: "umair@invozone.com",
      image: {
        mediaItemUrl: teamPic_Umair,
      },
    },
  ];

  const redefiningBusiness = [
    {
      image: {
        altText: "",
        mediaItemUrl:
          "https://static.invozone.com/uploads/2022/01/whyinvozone.png",
      },
      mobileImage: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2022/01/4th_Section_Illustration_Mobile-1.png",
      },
    },
  ];

  const whyInvozone = [
    {
      title: "Happy Clients",
      numbers: 150,
      color: "#5059ac",
      prefix: "true",
      image: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2022/02/Group-34089.svg",
      },
    },
    {
      title: "Team",
      numbers: 500,
      color: "#14a0c1",
      prefix: "true",
      image: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2022/02/Group-34086.svg",
      },
    },
    {
      title: "Projects",
      numbers: 300,
      color: "#67bc44",
      prefix: "true",
      image: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2022/02/Group-34087.svg",
      },
    },
    {
      title: "Established",
      numbers: 2014,
      color: "#4f7caa",
      prefix: "false",
      image: {
        mediaItemUrl: "https://static.invozone.com/uploads/2022/02/Vector.svg",
      },
    },
    {
      title: "Global Office",
      numbers: 4,
      color: "#00cd99",
      prefix: "false",
      image: {
        mediaItemUrl:
          "https://static.invozone.com/uploads/2022/02/Group-34088.svg",
      },
    },
  ];
  const ourStory = {
    mediaItemUrl: storyImage,
  };

  return (
    <MainLayout bgChanged={false}>
      <SEO title={seo.title} description={seo.metaDesc} />
      <Banner />
      <Curious
        text="Curious about our services and client stories?"
        link="/portfolio/"
        btnTxt="Check Out Our Portfolio"
      />
      {/* <Team team={team} /> */}
      <WhyIz redefine={redefiningBusiness} data={whyInvozone} />
      <OurStory ourStory={ourStory} />
      <ContactSales />
    </MainLayout>
  );
};

// export const query = graphql`
//   query {
//     wpPage(slug: { eq: "about-us" }) {
//       seo {
//         title
//         metaDesc
//       }
//     }
//     content: wpPage(slug: { eq: "acf-common" }) {
//       commonFields {
//         whyInvozone {
//           title
//           numbers
//           color
//           prefix
//           image {
//             mediaItemUrl
//           }
//         }
//         redefiningBusiness {
//           image {
//             altText
//             mediaItemUrl
//           }
//           mobileImage {
//             mediaItemUrl
//           }
//         }
//         ourStory {
//           mediaItemUrl
//         }
//         team {
//           name
//           title
//           whatsapp
//           linkedin
//           email
//           image {
//             mediaItemUrl
//           }
//         }
//       }
//     }
//   }
// `;

export default AboutUsPage;
